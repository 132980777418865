import React, { useState } from 'react';
import { Link } from "react-router-dom";
import FontAwesome from "../uiStyle/FontAwesome";
import { Swiper, SwiperSlide } from 'swiper/react';

import { Grid, Navigation } from 'swiper';

const TopBarTwo = () => {
    const [swiper, setSwiper] = useState(null);

    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };
    const params = {
        grid: { rows: 1, fill: 'row' },
        loops: true,
        slidesPerView: 1,
        navigation: {
            nextEl: '.prevBtn',
            prevEl: '.nextBtn'
        }
    };
    return (
        <div className="topbar white_bg" id="top">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 align-self-center">
                        <div className="trancarousel_area">
                            <p className="trand">Latest</p>
                            <div className="trancarousel nav_style1">
                                <Swiper modules={[Grid, Navigation]} getSwiper={setSwiper} className="trancarousel" {...params}>
                                    <SwiperSlide className="trancarousel_item">
                                        <p><Link to="/">Top 5 Most Charming Community Services of 2022</Link>
                                        </p>
                                    </SwiperSlide>
                                    <SwiperSlide className="trancarousel_item">
                                        <p><Link to="/"> Top 10 Best Speakers of New Zealand</Link>
                                        </p>
                                    </SwiperSlide>
                                    <SwiperSlide className="trancarousel_item">
                                        <p><Link to="/">Top 10 Best Movies of 2021 So Far: Great Movies To Watch</Link>
                                        </p>
                                    </SwiperSlide>
                                </Swiper>
                                <div className="navBtns">
                                    <button className="navBtn prevBtn" onClick={goPrev}><FontAwesome name="angle-left" />
                                    </button>
                                    <button className="navBtn nextBtn" onClick={goNext}><FontAwesome
                                        name="angle-right" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 align-self-center">
                        <div className="top_date_social d-flex justify-content-end text-end">
                            <div className="social1">
                                <ul className="inline">
                                    <li><Link to="/"><FontAwesome name="twitter" /></Link></li>
                                    <li><Link to="/"><FontAwesome name="facebook-f" /></Link></li>
                                    <li><Link to="/"><FontAwesome name="youtube-play" /></Link></li>
                                    <li><Link to="/"><FontAwesome name="instagram" /></Link></li>
                                </ul>
                            </div>
                            <div className="user3"><a href="https://www.dashboard.civilsociety.nz"><FontAwesome name="user-circle" /></a></div>

                            {/* <div className="lang d-none d-xl-block">
                                <ul>
                                    <li><Link to="#">English <FontAwesome name="angle-down" /></Link></li>

                                </ul>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopBarTwo;