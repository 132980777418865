import React, { useState } from 'react'
import { connect } from 'react-redux'

const WeatherOutput = ({ weatherData }) => {

    const { temp, temp_min, temp_max, feels_like, pressure, humidity } = weatherData.main || {};
    let iconUrl = `https://openweathermap.org/img/wn/${weatherData.weather[0].icon}.png`

    const [ option, setOption ] = useState('celsius')

    const toCelsius = temp => Math.round((5/9) * (temp - 32))
    const switchToC = () => setOption('celsius')
    const switchToF = () => setOption('fahrenheit')

    return (
        <div id="weather-data" className="row d-inline align-items-center">
           <p id="location" >{weatherData.name}, &nbsp;{weatherData.sys?.country}
                <img src={iconUrl} alt="weather icon"></img>
                {option === 'celsius' ? `${toCelsius(temp)} °C ` : `${temp} °F`}
            </p>
            <div className='button-weather d-inline'>
                <input type="radio" id="1f" checked={option === 'fahrenheit'} onChange={switchToF}/>
                <label for="1f" class="btn btn-default">°F</label>
                
                <input type="radio" id="1c" checked={option === 'celsius'} onChange={switchToC}></input>
                <label for="1c" class="btn btn-default">°C</label>
            </div>

            {/* <br></br><br></br>
            <p>Conditions: {weatherData.weather[0].main} - {weatherData.weather[0].description}</p>
            <p>Temperature: {option === 'fahrenheit' ? `${Math.round(temp)} °F` : `${toCelsius(temp)} °C `} </p>
            <p>Min temperature: {option === 'fahrenheit' ? `${Math.round(temp_min)} °F` : `${toCelsius(temp_min)} °C `} </p>
            <p>Max temperature: {option === 'fahrenheit' ? `${Math.round(temp_max)} °F` : `${toCelsius(temp_max)} °C `}</p>
            <p>Feels like: {option === 'fahrenheit' ? `${Math.round(feels_like)} °F` : `${toCelsius(feels_like)} °C `}</p>
            <p>Pressure: {pressure} hpa</p>
            <p>Humidity: {humidity} %</p>
            <p></p> */}
        </div>
    )  
}

const mapStateToProps = state => {
    return {
        fetching: state.fetching,
        weatherData: state.weather
    }
}

export default connect(mapStateToProps)(WeatherOutput)

