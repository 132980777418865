import React, { Fragment, useState } from 'react';
import { Link } from "react-router-dom";
import FontAwesome from "../uiStyle/FontAwesome";
import { Swiper, SwiperSlide } from 'swiper/react';

import { Grid, Navigation } from 'swiper';

// images
import trends1 from '../../assets/img/trending/transm23.jpg';
import trends2 from '../../assets/img/trending/transm24.jpg';
import trends3 from '../../assets/img/trending/transm25.jpg';
import trends4 from '../../assets/img/trending/transm26.jpg';
import trends5 from '../../assets/img/trending/transm27.jpg';

import { mostViewSort } from "../../utils/commonFunctions";

const trends = [
    {
        image: trends1,
        category: 'SOCIETIES',
        date: 'June 06, 2022',
        title: 'Copa America: Luis Suarez from devastated US',
    },
    {
        image: trends2,
        category: 'SOCIETIES',
        date: 'June 06, 2022',
        title: 'Copa America: Luis Suarez from devastated US',
    },
    {
        image: trends3,
        category: 'SOCIETIES',
        date: 'June 06, 2022',
        title: 'Copa America: Luis Suarez from devastated US',
    },
    {
        image: trends4,
        category: 'SOCIETIES',
        date: 'June 06, 2022',
        title: 'Copa America: Luis Suarez from devastated US',
    },
    {
        image: trends5,
        category: 'SOCIETIES',
        date: 'June 06, 2022',
        title: 'Copa America: Luis Suarez from devastated US',
    },
    {
        image: trends1,
        category: 'SOCIETIES',
        date: 'June 06, 2022',
        title: 'Copa America: Luis Suarez from devastated US',
    },
    {
        image: trends2,
        category: 'SOCIETIES',
        date: 'June 06, 2022',
        title: 'Copa America: Luis Suarez from devastated US',
    },
    {
        image: trends3,
        category: 'SOCIETIES',
        date: 'June 06, 2022',
        title: 'Copa America: Luis Suarez from devastated US',
    },
    {
        image: trends4,
        category: 'SOCIETIES',
        date: 'June 06, 2022',
        title: 'Copa America: Luis Suarez from devastated US',
    },
    {
        image: trends5,
        category: 'SOCIETIES',
        date: 'June 06, 2022',
        title: 'Copa America: Luis Suarez from devastated US',
    },
];

const TrendingCarousel = () => {
    const [swiper, setSwiper] = useState(null);

    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };

    const params = {
        grid: { rows: 6, fill: 'row' },
        loops: true,
        slidesPerView: 1,
        slidesPerColumn: 6,
        navigation: {
            nextEl: '.nextBtn2',
            prevEl: '.prevtBtn2'
        },
    };
    return (
        <div className="popular_carousel multipleRowCarousel nav_style1">
            <Swiper modules={[Grid, Navigation]} getSwiper={setSwiper} {...params}>
                {mostViewSort(trends).map((item, i) => (
                    <SwiperSlide key={i}
                        className={`single_post type10 type16 widgets_small ${i + 2 < trends.length ? 'mb15' : ''}`}>
                        <div className="post_img">
                            <div className="img_wrap">
                                <Link to="/">
                                    <img src={item.image} alt="thumb" />
                                </Link>
                            </div>
                        </div>
                        <div className="single_post_text">
                            <h4><Link to="/post1">{item.title}</Link></h4>
                            <div className="meta4"><Link to="/">{item.category}</Link></div>
                            {i + 2 < trends.length ? <Fragment>
                                <div className="space-10" />
                                <div className="border_black" />
                                <div className="space-10" />
                            </Fragment> : ''}
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className="navBtns">
                <div onClick={goPrev} className="navBtn prevtBtn2"><FontAwesome name="angle-left" /></div>
                <div onClick={goNext} className="navBtn nextBtn2"><FontAwesome name="angle-right" /></div>
            </div>
        </div>
    );
};

export default TrendingCarousel;