import { combineReducers } from 'redux'
import metaReducer from "./metaReducer";

//Calendar
import calendar from "../calendar/reducer";

const rootReducer = combineReducers({
    meta: metaReducer,
    calendar,
});
export default rootReducer