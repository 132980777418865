import React, { Fragment, useState } from 'react';
import { Link } from "react-router-dom";
import FontAwesome from "../uiStyle/FontAwesome";
import { Swiper, SwiperSlide } from 'swiper/react';
import { mostViewSort } from "../../utils/commonFunctions";
import { Navigation } from 'swiper';

// images
import whats21 from '../../assets/img/whats/whats21.jpg';
import whats22 from '../../assets/img/whats/whats22.jpg';
import whats23 from '../../assets/img/whats/whats23.jpg';
import whats24 from '../../assets/img/whats/whats24.jpg';
import whats25 from '../../assets/img/whats/whats25.jpg';

const posts = [
    {
        image: whats21,
        category: 'SOCIETIES',
        title: 'Copa: Luis Suarez from devastated US',
    },
    {
        image: whats22,
        category: 'SOCIETIES',
        title: 'Copa: Luis Suarez from devastated US',
    },
    {
        image: whats23,
        category: 'SOCIETIES',
        title: 'Copa: Luis Suarez from devastated US',
    },
    {
        image: whats24,
        category: 'SOCIETIES',
        title: 'Copa: Luis Suarez from devastated US',
    },
    {
        image: whats25,
        category: 'SOCIETIES',
        title: 'Copa: Luis Suarez from devastated US',
    },
];

const Whatsnew = ({ className, title }) => {
    const [swiper, setSwiper] = useState(null);

    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };

    const params = {
        grid: { rows: 6, fill: 'row' },
        loops: true,
        slidesPerView: 1,
        slidesPerColumn: 6,
        navigation: {
            nextEl: '.nextBtn5',
            prevEl: '.prevtBtn5'
        },
    };
    return (
        <div className={`${className ? className : 'white_bg padding20 border-radious5 sm-mt30'}`}>
            <h2 className="widget-title">{title}</h2>
            <div className="popular_carousel multipleRowCarousel nav_style1">
                {/*CAROUSEL START*/}
                <Swiper modules={[Navigation]} getSwiper={setSwiper} {...params}>
                    {mostViewSort(posts).map((item, i) => (
                        <SwiperSlide key={i} className="single_post type10 type16 widgets_small">
                            <div className="post_img">
                                <div className="img_wrap">
                                    <Link to="/">
                                        <img src={item.image} alt="thubm" />
                                    </Link>
                                </div>
                            </div>
                            <div className="single_post_text">
                                <h4><Link to="/post1">{item.title}</Link></h4>
                                <div className="meta4"><Link to="/">{item.category}</Link></div>
                                {i + 1 < posts.length ? <Fragment>
                                    <div className="space-5" />
                                    <div className="border_black" />
                                    <div className="space-15" />
                                </Fragment> : null}
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="navBtns">
                    <div onClick={goPrev} className="navBtn prevtBtn5"><FontAwesome name="angle-left" /></div>
                    <div onClick={goNext} className="navBtn nextBtn5"><FontAwesome name="angle-right" /></div>
                </div>
                {/*CAROUSEL END*/}
            </div>
        </div>
    );
};

export default Whatsnew;