import React from 'react';
import { Link } from "react-router-dom";
import FooterCopyright from "../FooterCopyright";
import FollowUs from "../FollowUs";
import NewsLetter from "../NewsLetter";
import FontAwesomeIcon from "../uiStyle/FontAwesome";
import FooterNewsCategories from "../FooterNewsCategories";
import { Button } from "reactstrap";

// images
import logo from '../../assets/img/logo/logo.png';
import banner from '../../assets/img/add/add21.jpg';
import phone_black from '../../assets/img/icon/phone_black.png';
import speaker_black from '../../assets/img/icon/speaker_black.png';
import envelope_black from '../../assets/img/icon/envelope_black.png';

const FooterAreaTwo = () => {
    return (
        <div className="footer footer_area3 white_bg">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-4">
                        <div className="single_footer3 mb30">
                            <div className="logo">
                                <Link to="/">
                                    <img src={logo} alt="logo" />
                                </Link>
                            </div>
                            <div className="cta">
                                <div className="social2">
                                    <ul className="inline d-inline-flex">
                                        <li className="p-1"><Link to="#"><FontAwesomeIcon name="twitter"/></Link></li>
                                        <li className="p-1"><Link to="#"><FontAwesomeIcon name="facebook-f" /></Link></li>
                                        <li className="p-1"><Link to="#"><FontAwesomeIcon name="youtube-play" /></Link></li>
                                        <li className="p-1"><Link to="#"><FontAwesomeIcon name="instagram" /></Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="space-15" />
                            <div className="border_black" />
                        </div>
                        <div className="footer_contact">
                            <h3 className="widget-title2">Civil Society News & Services </h3>
                            <div className="single_fcontact">
                                <div className="fcicon">
                                    <img src={phone_black} alt="phone_black" />
                                </div>
                                <Link to="/">On your mobile</Link>
                            </div>
                            <div className="single_fcontact">
                                <div className="fcicon">
                                    <img src={speaker_black} alt="speaker_black" />
                                </div>
                                <Link to="/">On smart speakers</Link>
                            </div>
                            <div className="single_fcontact">
                                <div className="fcicon">
                                    <img src={envelope_black} alt="envelope_black" />
                                </div>
                                <Link to="/">Contact Civil Society</Link>
                            </div>
                        </div>
                        <div className="space-30" />
                        <div className="border_black" />
                        <div className="space-30" />
                        <div className="single_footer_nav mb30">
                            <FooterNewsCategories />
                        </div>
                        {/* <div className="space-30" />
                        <div className="border_black" />
                        <div className="space-30" />
                        <TwitterfeedTwo /> */}
                    </div>
                    <div className="col-lg-8 col-md-6">
                        <div className="contacts3">
                            <div className="single_contact3">
                                <h6>Talk to Us!</h6>
                                <Link to="/">+64 22 428 1770</Link>
                                {/* <Link to="/">+880 1945 381758</Link> */}
                            </div>
                            <div className="single_contact3">
                                <h6>Let's Chat</h6>
                                <a href="mailto:hello@CivilSociety.com">consult@CivilSociety.nz</a>
                            </div>
                            <div className="button-items single_contact3">
                                <h6>Shere Your Story !?</h6>
                                <a href="https://www.dashboard.civilsociety.nz">
                                    <Button color="primary" type="button" className="waves-effect waves-light me-1">
                                        Get Started <i className="ri-arrow-right-line align-middle ms-2"></i>
                                    </Button>
                                </a>
                            </div> 
                        </div>
                        <div className="space-30" />
                        <div className="border_black" />
                        <div className="space-30" />
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="single_footer_nav">
                                    <h3 className="widget-title2">Events & Trainings</h3>
                                    <div className="row">
                                        <div className="col-lg-6 text-dark">
                                            <ul>
                                                <li><Link to="/">Cultural Celebration</Link>
                                                </li>
                                                <li><Link to="/">Conference / Discussion Panel</Link>
                                                </li>
                                                <li><Link to="/">Trainings / Workshops</Link>
                                                </li>
                                                <li><Link to="/">Other Services</Link>
                                                </li>
                                                <li><Link to="/">Health</Link>
                                                </li>
                                                <li><Link to="/">Magazine</Link>
                                                </li>

                                            </ul>
                                        </div>
                                        <div className="col-lg-6">
                                            <ul>
                                                <li><Link to="/">Language</Link>
                                                </li>
                                                <li><Link to="/">Personal Development</Link>
                                                </li>
                                                <li><Link to="/">Sport Activities</Link>
                                                </li>
                                                <li><Link to="/">Buisness</Link>
                                                </li>
                                                <li><Link to="/">Public Editor</Link>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="download_btn">
                                    <div className="space-30" />
                                    <div className="border_black" />
                                    <div className="space-30" />
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h3 className="widget-title">Civil Society app download</h3>
                                            <p>Free sign & download, iOS & Android app</p>
                                            <div className="space-30" />
                                            <div className="d-inline-flex p-2 ">
                                                <div className="download_btn_group">
                                                    <Link className="app_btn" to="/"> <FontAwesomeIcon name="android"/>
                                                        Download on the <span>google play</span>
                                                    </Link>
                                                </div>
                                                <div className="download_btn_group">
                                                    <Link className="app_btn" to="/"> <FontAwesomeIcon name="apple" />
                                                        Download on the <span>app store</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                            <div className="col-lg-6">
                                <div className="download_btn_group">
                                    <NewsLetter input_white={true} className="news3bg mb30 border-radious5" />
                                    <FollowUs title="Follow Us" />
                                    <div className="banner2 mb30 ">
                                        <Link to="/" className="border-radious5">
                                            <img src={banner} alt="banner" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                    </div>


                </div>
            </div>
            <FooterCopyright />
        </div>
    );
};

export default FooterAreaTwo;