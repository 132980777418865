import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import BreadCrumb from "../../components/BreadCrumb";
import { Link } from "react-router-dom";
import BannerSection from "../../components/BannerSection";
// images
import author1 from '../../assets/img/logo/logo.png';



const TermsConditionsPage = () => {


    return (
        <Fragment>
            <BreadCrumb className="shadow5" title="Archive">
                <Fragment>
                    <div className="space-50" />
                    <div className="row">
                        <div className="col-12">
                            <div className="author_about">
                                <div className="author_img">
                                    <div className="author_wrap">
                                        <img src={author1} alt="author1" />
                                    </div>
                                </div>
                                <div className="author_content centralize"><Link to="/">Terms & Conditions</Link></div>
                                <p className="centralize">T&Cs are under review, last updated on 29 May, 2022.</p>
                                <br />
                                <p className="fw-bold">A. GENERAL: </p>
                                <br />
                                <p className="indentor fst-italic"> 1.	Application of the Terms and Conditions:</p>
                                <br />
                                <p>Please read these terms and conditions (these “T&Cs”) carefully as they govern the relationship between Civil Society News & Services (CSNS) and you (“you” or “Client”).  By accessing and using the Site or any Product, you are agreeing to be legally bound by these T&Cs.  If you do not accept these T&Cs, do not use the Site or purchase such Product. These T&Cs are not subject to negotiation or one-off amendment. </p>
                                <br />
                                <p> These T&Cs (including Section B – User-Generated Content and Section C – Business Terms) shall apply to the use of the Site and the Products.  The provisions included in Sections D (Books), E (Subscriptions (Publications and Databases)), F (Events), G (Services) and H (Marketing Solutions/Advertising) shall apply depending on the types of Products being supplied by CSNS in each instance (either exclusively or together where more than one category of Product is being supplied). If any inconsistency arises between a general term and a Product-specific term, the Product-specific term shall prevail.</p>
                                <br />
                                <p> Section 19, below, contains additional definitions relating to these T&Cs.</p>
                                <br />
                                <p className="indentor fst-italic"> 2.	Accessing the Site and Online Services:</p>
                                <br />
                                <p> Certain Online Services are only accessible to CSNS subscribers and not to visitors. In addition, CSNS may not offer or make available all of our Products or Content to residents of certain countries.</p>
                                <p> A license for access to an Online Service is individual to a particular Client. Additional Users beyond the primary User must purchase additional licenses to use the Online Service. A single registration/subscription is for an individual User license, not a license for the entire organization of which the individual User is a part.</p>
                                <br />
                                <p> CSNS uses a single sign-on process to access certain Online Services.  Further information about the single sign-on process can be found on this Site’s homepage under the link “Sign in FAQ”.  Each User’s access to certain Online Services is based on a cookie placed on a device designated by the User.  You are solely responsible for all activities that occur on any of your Users’ accounts. You agree to notify us immediately of any unauthorized use of the User’s account or any other breach of security or personal data breach.  CSNS shall not be liable for any loss that you may incur as a result of a third party using one of your User’s accounts, either with or without such User’s knowledge. You may be held liable for losses incurred by CSNS or another party due to a third party using one of your User’s accounts.</p>
                                <p> As part of the online registration process, CSNS will collect certain limited information about each User (“Registration Data”). All Registration Data provided by or on behalf of User must be current, complete and accurate, and User is responsible for updating such Registration Data as necessary. CSNS reserves the right to terminate User’s access to the service immediately in the event any Registration Data is found to be inaccurate, incomplete or not current at any time.</p>
                                <br />
                                <p className="indentor fst-italic"> 3.	Termination and Amendment of These T&Cs:</p>
                                <br />
                                <p> These T&Cs are effective until terminated or modified by CSNS.</p>
                                <br />
                                <p>CSNS may terminate these T&Cs with respect to you or any other person without notice at any time for any reason.  In addition, if CSNS determines, in its sole discretion, that you have breached any of these T&Cs, CSNS reserves the right to terminate access to the Site and any Product and take any other action that CSNS, in CSNS’s sole discretion, deems to be appropriate.  All terms that by their nature should survive termination of these T&Cs will survive.</p>
                                <br />
                                <p>CSNS may make reasonable updates and changes to these T&Cs from time to time, which will be posted on the Site.  You are expected to check these T&Cs regularly to ensure your ongoing compliance with the current T&Cs.  Your continued use of the Site and the Online Services after changes are posted constitutes your acceptance of the T&Cs as modified by the posted changes. If you do not agree to any changes to these T&Cs, you should cease using the Site and Online Services and contact CSNS via email: info@csns.nz.</p>
                                <br />
                                <p className="indentor fst-italic"> 4.	Privacy Policy:</p>
                                <br />
                                <p>CSNS processes information about you in accordance with CSNS’s Privacy Policy, which you hereby confirm you have read and understood. The CSNS is committed to protecting your privacy and any data collected that concerns you. By affirmatively accepting the T&Cs and using this website you agree to The Media People’s use of the data collected in accordance with this Privacy Notice and Cookie Policy. This Notice indicates the type of processes that may result in data being collected about you.</p>
                                <br />
                                <p className="indentor fst-italic"> 5.	Ownership and Intellectual Property Rights:</p>
                                <br />
                                <p>The legal and beneficial interest in all copyrights, patents, trademarks, service marks, design rights (whether registered or unregistered), database rights, proprietary information rights and all other proprietary rights as may exist anywhere in the world together with applications associated with any such rights (collectively “Intellectual Property Rights” or “IPR”) relating to the Site and any Products belong to CSNS or CSNS’s licensors, as the case may be, at all times.</p>
                                <br />
                                <p>Except as expressly permitted none of the Site, any Product or the content displayed on the Site or in any Product, including, but not limited to, article, text, data, reports, opinions, images, photos, graphics, graphs, charts, animations, templates, advice and video (the “Content”) may be copied, reproduced, framed, hyperlinked, republished, downloaded, uploaded, posted, disseminated, sold, transmitted, licensed or otherwise distributed in whole or in part, directly or indirectly, bundled or unbundled (but keeping them in their original submitted form so that the ) either: (i) for purposes of reselling the Content or Product or otherwise charging others to use the Content or Product; or (ii) to any third-party.</p>
                                <br />
                                <p>You obtain no rights or interests, including any Intellectual Property Rights, in the Site, the Products or any Content, pursuant to or arising out of this Agreement or otherwise. You acknowledge that you have no claim to ownership of any Product, Content or Intellectual Property Right simply because of your use of or access thereto.</p>
                                <br />
                                <p>To request permission to use any Content or other CSNS material, please contact info@csns.nz.</p>
                                <br />
                                <p>NOTICES AND PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT INFRINGEMENT</p>
                                <br />
                                <p>Notifications of claimed copyright infringement under New Zealand Copyright Act 1994 should be sent to our designated contact info@csns.nz. ALL INQUIRIES NOT SUBMITTED ACCORDING TO THIS PROCEDURE WILL RECEIVE NO RESPONSE.</p>
                                <br />
                                <p className="indentor fst-italic"> 6.	Product Identification by CSNS:</p>
                                <br />
                                <p>You acknowledge and agree that CSNS may edit and amend parts of the Products. Any such adjustment will have no meaningful impact on the meaning and validity of the Products, but will serve as a means of uniquely identifying the Products as having been supplied by us to you and you accept that this is a legitimate and lawful measure for CSNS to undertake in order to protect CSNS’s and/or CSNS’s partners’ Intellectual Property Rights. You also agree that if any third party has access to Products that can be identified as having any unique adjustments made by you, then a prima facie breach of these T&Cs on your part may be assumed by CSNS and the necessary remedies shall be undertaken by CSNS as set down in these T&Cs or otherwise available at law.</p>
                                <br />
                                <p className="indentor fst-italic"> 7.	Disclaimer of Warranties:</p>
                                <br />
                                <p>CSNS makes all reasonable efforts to ensure the Products and Online Services are provided to you in accordance with any specifications set out in the Order Confirmation and accepted by CSNS. Subject to the foregoing and applicable law:</p>
                                <br />
                                <p>CSNS expressly disclaims all warranties of any kind, whether express or implied, including, but not limited to, those of merchantability, satisfactory quality, title, fitness for a particular purpose, and non-infringement.  Without limitation of the foregoing, CSNS makes no warranty: (a) that the products will meet your requirements; (b) that the online services will be uninterrupted, timely, complete or error-free; (c) as to the results that may be obtained from the use of the products; (d) as to the accuracy of any other information obtained through the products; or (e) that any defects will be corrected.</p>
                                <br />
                                <p className="indentor fst-italic"> 8.	Use of Products and Online Services:</p>
                                <br />
                                <p>You hereby acknowledge and agree that in connection with the Site and the Products:</p>
                                <br />
                                <p>(a)    The Site and the Products are provided by CSNS on an “as is” basis, and your access to and/or use of the Site and the Products is at your sole risk.</p>
                                <br />
                                <p>(b)    Any material and/or data downloaded or otherwise obtained through the use of the Site or the Products is done at your sole risk. You are solely responsible for any damage to your computer system or loss of data that results from the download of such material and/or data.</p>
                                <br />
                                <p>(c)    It is your responsibility to review the materials and data downloaded by you and to make further inquiry to make sure such materials and data comply and are consistent with applicable laws, rules and regulations in the country, province, state any other applicable jurisdiction where you are accessing the materials or data as well as any policies and procedures that may be applicable to you or your employer.  The materials and data provided are tools for your use but do not represent definitive advice given that your particular circumstances are unique. It is incumbent on you to assess any applicable laws, rules, regulations, policies and procedures relevant to your use of the Sites, the Products and any materials or data.</p>
                                <br />
                                <p>(d)    The Products do not constitute any form of advice, recommendation, representation, endorsement or arrangement by CSNS or any of its employees and is not intended to be relied upon by you or any other Client in making (or refraining from making) any specific investment or other decisions. You should obtain appropriate independent advice before making any such decision.</p>
                                <br />
                                <p>(e)    You are solely responsible for the selection, suitability and use of the Products and acknowledge that except as stated above CSNS does not provide any additional warranties or guarantees relating to the Products.</p>
                                <br />
                                <p>(f)     By using a Product, you agree that any such Product is only for your general information and use and is not intended to address your particular requirements.</p>
                                <br />
                                <p>(g)    Any agreements, transactions or other arrangements made between you and any third party named on (or linked to from) the Site are at your sole discretion and responsibility.</p>
                                <br />
                                <p>(h)    Any information that you receive via the Site whether or not it is classified as “real time”, may have ceased to be current by the time it reaches you and any share price information may be rounded up/down and therefore not entirely accurate.</p>
                                <br />
                                <p>(i)     CSNS from time to time uses sources in providing the Products whose legitimacy and accuracy CSNS uses reasonable endeavors to confirm, and if you believe that any of the information contained in any is inaccurate or misrepresented, you will bring this to the notice of CSNS and provide information to assist us in correcting any such deficiencies.  CSNS will endeavor, to the extent reasonable, to rectify such deficiencies in good faith, and that such action will be deemed to fulfil CSNS’s obligations to you.  You agree that you will take no further action in law with respect to such alleged inaccurate or misrepresented information.</p>
                                <br />
                                <p>(j)     You agree to use the Site, Products and Content only for lawful purposes, and in a manner that does not infringe the rights of, or restrict or inhibit the use and enjoyment of the Site by any third party.  Such restriction or inhibition includes, without limitation, conduct which is unlawful, or which may harass or cause distress or inconvenience to any person and the transmission of obscene or offensive content or disruption of normal flow of dialogue within the Site.</p>
                                <br />
                                <p>(k)    You shall not access and/or use the Site or any Online Service in any manner that could damage, disable, overburden, impair or otherwise interfere with or disrupt the Site or Online Service or any networks or security systems of CSNS.</p>
                                <br />
                                <p>(l)     You are entirely liable for activities conducted by you in connection with your browsing and use of the Site and the Online Services. If you are dissatisfied with any content or the Site, any Online Service or with these T&Cs, your sole and exclusive remedy is to stop using the Site or Online Service. CSNS will not pay you any damages in connection with your browsing or use of the Site or any Online Service.</p>
                                <br />
                                <p>(m)    You shall honor all reasonable requests by CSNS to protect CSNS’s proprietary interests in the Site, Online Services and Content.</p>
                                <br />
                                <p>(n)    CSNS may impose whatever security measures CSNS reasonably deems appropriate to ensure compliance with these T&Cs, including covert and overt copy-detection and license awareness SOCIETIES and encoding of requestor/user information in printed and electronic formats.</p>
                                <br />
                                <p>(o)    No rights are granted hereunder except as expressly set forth in these T&Cs.</p>
                                <br />
                                <p className="indentor fst-italic"> 9.	Compliance with Laws.</p>
                                <br />
                                <p>You agree to comply with all applicable laws, statutes, ordinances and regulations regarding your use of the Site and the Products. CSNS may, in its sole discretion, report actual or perceived violations of law to law enforcement or appropriate authorities.  If CSNS becomes aware, through a complaint or otherwise, of any potential or suspected violation of these T&Cs, CSNS may (but is not obligated to) conduct an investigation to determine the nature and extent of the suspected violation and the appropriate enforcement action, during which investigation CSNS may suspend services to any customer being investigated and/or remove any material from CSNS’s servers. You agree to cooperate fully with any such investigation. You acknowledge that violations of the T&Cs could be subject to criminal or civil penalties.</p>
                                <br />
                                <p className="indentor fst-italic"> 10.	Compliance with CSNS policy.</p>
                                <br />
                                <p>You agree to indemnify, defend, release and hold CSNS, its officers, directors, suppliers, service providers, co-branders or other partners, agents and employees, and its affiliates, harmless from all damages, liability, fees, costs, loss of business profits, taxes, penalties or sanctions, and interest of any nature, including reasonable fees of attorneys and other professionals CSNS may incur due to or arising out of  or relating to any claims, demands, actions or proceedings, whether or not brought to suit, alleged or initiated against CSNS by any third party in any way arising out of or related to anything you submit, transmit through or upload to the Sites, your use of the Sites and the Products, any violation by you of these T&Cs, your provision of information to CSNS that is untrue, inaccurate, fraudulent, or out of date, any communications by you, your violation of anyone’s proprietary or confidential information, IPR, or any allegation that you are in violation of any right, license, law, statute, regulation, rule, order or ordinance.</p>
                                <br />
                                <p>In connection with any alleged violation by you of any IPR, you shall allow us (or CSNS’s licensors) to control any proceedings arising as a result of any infringement, threatened infringement or claim relating to the IPR. You shall make no admission as to liability nor agree any settlement or compromise of any action on CSNS’s behalf. You shall, at CSNS’s request and cost, offer such assistance as CSNS may reasonably request in relation to any proceedings relating to CSNS’s IPR. Any recovery obtained from such proceedings shall accrue solely for CSNS’s benefit.</p>
                                <br />
                                <p className="indentor fst-italic"> 11.	Limitation of Liability:</p>
                                <br />
                                <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL CSNS AND/OR ITS AFFILIATES BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA, OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE OR ANY PRODUCT, WITH THE DELAY OR INABILITY TO USE THE SITE OR PRODUCT, THE PROVISION OF OR FAILURE TO PROVIDE PRODUCTS, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, CONTENT, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, ANY PRODUCT, OR OTHERWISE ARISING OUT OF THE USE OF SITE OR PRODUCT, HOWEVER CAUSED AND BASED ON ANY THEORY OF LIABILITY, AND WHETHER OR NOT FOR BREACH OF CONTRACT, NEGLIGENCE, OR OTHERWISE, AND WHETHER OR NOT CSNS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
                                <br />
                                <p>SUBJECT TO THE FOREGOING, CSNS’S LIABILITY HEREUNDER IS LIMITED TO ANY FEES PAID BY YOU TO CSNS DURING THE IMMEDIATELY PRECEDING 12 MONTH PERIOD.</p>
                                <br />
                                <p>CSNS reserve the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Online Services (or any part thereof) with or without notice. You agree that CSNS shall not be liable to you or your clients or to any third party for any modification, suspension or discontinuance of the Online Services, subject to refund of any prepaid fees.</p>
                                <br />
                                <p>CSNS will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of the Site or to your downloading of any material posted on it, or on any website linked to it.</p>
                                <br />
                                <p className="indentor fst-italic"> 12.	Confidentiality:</p>
                                <br />
                                <p>Neither party shall, except as strictly required to perform its rights and obligations, use, copy, adapt, alter, disclose, publicize or in any way part with possession of any information of the other which comes into its possession as a result of the Agreement, including the existence and terms of the Agreement. This obligation shall not apply to any information that: (a) the receiving party can prove was in its possession at the date it was received or obtained without a corresponding obligation of confidentiality; or (b) the receiving party obtains from another person in accordance with the rights of such person in the information; or (c) comes into the public domain otherwise than through the default or negligence of the receiving party; or (d) is independently developed by or for the receiving party, without reference to any information provided in connection herewith.</p>
                                <br />
                                <p className="indentor fst-italic"> 13.	Force Majeure:</p>
                                <br />
                                <p>CSNS shall not be liable for any delay or failure to perform any obligation under this Agreement insofar as the performance of such obligation is prevented by an event beyond CSNS’s reasonable control, including but not limited to, earthquake, fire, flood or any other natural disaster, labor dispute, riot, revolution, terrorism, acts of restraint of government or regulatory authorities, failure of computer equipment and failure or delay of sources from which data is obtained.</p>
                                <br />
                                <p className="indentor fst-italic"> 14.	Viruses, Hacking and Other Offences:</p>
                                <br />
                                <p>You expressly agree not to use the Site or Product in any manner or for any purpose (a) that is prohibited by these T&Cs or any law or regulation, or (b) to facilitate the violation of any law or regulation.  Without limitation of the foregoing, you expressly agree that you shall not:</p>
                                <br />
                                <p>(a)    harm the Site or any Online Service by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful;</p>
                                <br />
                                <p>(b)    attempt to gain unauthorized access to the Site or any Online Service, the servers on which the Site or Online Services are stored or any server, computer or database connected to any Site or Online Service;</p>
                                <br />
                                <p>(c)    attack the Site via a denial-of-service attack or a distributed denial-of service attack;</p>
                                <br />
                                <p>(d)    use or attempt to use any “deep-link,” “scraper,” “robot,” “bot,” “spider,” “data mining,” “computer code” or any other automated device, program, tool, algorithm, process or methodology or manual process having similar processes or functionality, to access, acquire, copy, decompile, reverse engineer, or monitor any portion of the Site or Online Service or any data or content found on or accessed through the Site or Online Service without our prior express written consent;</p>
                                <br />
                                <p>(e)    obtain or attempt to obtain through any means any materials or information on the Site or in any Online Service that have not been intentionally made publicly available either by their public display on the Site or through their accessibility by a visible link on the Site;</p>
                                <br />
                                <p>(f)     in any way bypass or circumvent any other measure employed to limit or prevent access to the Site or any Online Service or any Content;</p>
                                <br />
                                <p>(g)    violate the security of the Site or attempt to gain unauthorized access to the Site, data, materials, information, computer systems or networks connected to any server associated with the Site, through hacking, password mining or any other means;</p>
                                <br />
                                <p>(h)    interfere or attempt to interfere with the proper working of the Site or any activities conducted on or through the Site, including accessing any data, content or other information prior to the time that it is intended to be available to the public on the Site; or</p>
                                <br />
                                <p>(i)     take or attempt any action that, in the sole discretion of this Site’s operators, imposes or may impose an unreasonable or disproportionately large load or burden on the Site or such operation’s infrastructure.</p>
                                <br />
                                <p>By breaching this provision, you might commit a criminal offence. CSNS will report any such breach to the relevant law enforcement authorities and CSNS will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use any Site will cease immediately and any Subscriptions or other Orders related to you will be terminated without refund.</p>
                                <br />
                                <p className="indentor fst-italic"> 15.	Links from and to CSNS’s Sites:</p>
                                <br />
                                <p>Where a Site contains links to other websites and resources provided by third parties, these links are provided for your information and convenience only. CSNS has no control over the contents of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them. You agree CSNS is not responsible for the content or operation of such websites, and that CSNS shall have no liability to you or any other person or entity for the use of third-party websites.</p>
                                <br />
                                <p>A link on a Site to another website does not imply or mean CSNS endorses the content on that website or the operator or operations of that website. You are solely responsible for determining the extent to which you may use any content at any other websites to which you link from the Site. When you use those websites, you will be legally bound by the specific terms of use posted on such websites.</p>
                                <br />
                                <p>You may link to the Site, provided you do so in a way that is fair and legal and which in CSNS’s reasonable opinion does not damage CSNS’s reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on CSNS’s part where none exists. You must only establish links from your own website. The Site must not be framed on any other website, nor may you create a link to any part of the Site other than the home page without CSNS’s written permission. CSNS reserves the right to withdraw linking permission without notice.</p>
                                <br />
                                <p className="indentor fst-italic"> 16.	Anti-Bribery and Anti-Corruption:</p>
                                <br />
                                <p>CSNS is committed to conducting its business around the world with the highest degree of integrity. This commitment includes a zero-tolerance approach towards all forms of bribery and corruption. CSNS has implemented a formal compliance program designed to minimize the risk of bribery and corruption occurring within its business and this program includes a comprehensive anti-bribery and corruption policy which has been formally approved by CSNS’s Board of Directors. A copy of this policy may be available on request.  Breaches of any applicable anti-bribery and corruption laws by customers, suppliers, joint venture partners, agents or other third-party contractors and advisors to CSNS may result in immediate termination for breach of all contracts with CSNS.</p>
                                <br />
                                <p className="indentor fst-italic"> 17.	Notices and Contact Information:</p>
                                <br />
                                <p>Any notice, invoice or other document shall be duly given by CSNS if sent by email or post to the individual contact using the details as supplied to us. You should direct all communication to your CSNS contact person as well as to CSNS’s customer services manager located at CSNS’s Auckland office. CSNS’s contact information is as follows:</p>
                                <br />
                                <p><span class="fw-bold">Civil Society News & Services </span>(Civil Society Media Ltd.)</p>
                                <p>44 Gifford Avenue (temporary)</p>
                                <p>Wesley, Auckland 1041</p>
                                <p>New Zealand</p>
                                <p>Tel: +64 (0)22 428 1770</p>
                                <p>Email: consult@CivilSociety.nz</p>
                                <br />
                                <p className="indentor fst-italic"> 18.	Other:</p>
                                <br />
                                <p>(a) <span className="indentor fst-italic">Integration. </span> This Agreement, together with any Subscription materials, constitutes the entire understanding between the parties relating to contents hereof and supersedes any previous agreements and understandings whether oral or written.</p>
                                <br />
                                <p>(b) <span className="indentor fst-italic">Governing Law; Jurisdiction. </span> This Agreement shall be governed by, and construed with the laws of New Zealand and the courts of New Zealand shall have exclusive jurisdiction to settle any dispute with the CSNS contracting entity (as identified on the relevant Order Form) that may arise out of, under.</p>
                                <p>or in connection with this Agreement, and for those purposes irrevocably submit all disputes to the jurisdiction of the such courts.</p>
                                <br />
                                <p>(c) <span className="indentor fst-italic">Assignment. </span> You may not assign, sub-license or otherwise transfer any of your rights under the Agreement.</p>
                                <br />
                                <p>(d) <span className="indentor fst-italic">Severability. </span> If any provision of these T&Cs is found to be invalid by any court having competent jurisdiction, the invalidity of that provision will not affect the validity of the remaining provisions of these T&Cs, which shall remain in full force and effect and shall be carried out as closely as possible according to the original intent.</p>
                                <br />
                                <p>(e) <span className="indentor fst-italic">No Waiver. </span> Failure by either party to exercise any right or remedy under these T&Cs does not constitute a waiver of that right or remedy.</p>
                                <br />
                                <p className="indentor fst-italic"> 19.	Definitions:</p>
                                <br />
                                <p>The following terms when used in these T&Cs shall have the associated meanings.  References to the singular include the plural, and references to one gender include all other genders.</p>
                                <br />
                                <Container>
                                    <Row>
                                        <Col>“Agreement”</Col>
                                        <Col xs={10}>These T&Cs, together with any Order Confirmation issued by CSNS to you.</Col>
                                    </Row>
                                    <Row>
                                        <Col>“Book(s)”</Col>
                                        <Col xs={10}>A print or digital product consisting of multiple chapters covering a specialist topic published by CSNS and not forming part of a Subscription. Books are supplied by CSNS under those terms set out in Section (D) – Books.</Col>
                                    </Row>
                                    <Row>
                                        <Col>“Client”, “you” or “your”</Col>
                                        <Col xs={10}>Any person, company or other body that enters into a contract with CSNS on these T&Cs by placing an Order with CSNS. This may be to post, to receive or have access to any Products, (regardless of the mode of its delivery to the Client) or to attend an Event hosted (but not advertised) by CSNS. One Order may not extend to or include other companies that may be part of the same group as the Client.</Col>
                                    </Row>
                                    <Row>
                                        <Col>“Data”</Col>
                                        <Col xs={10}>The facts, figures and statistics researched and compiled by CSNS that reside in a Database Product and constitute a component of Products.</Col>
                                    </Row>
                                    <Row>
                                        <Col>“Database Product”</Col>
                                        <Col xs={10}>A product distributed, supplied or made available or accessible by CSNS which wholly or mainly comprises a compilation or combination of data, statistics, research material and/or other information and which is presented wholly or mainly in the form of a database, dataset or otherwise as a compilation or combination of accessible, retrievable and/or searchable Products. CSNS’s Database Products form part of a Subscription that a Client can acquire.</Col>
                                    </Row>
                                    <Row>
                                        <Col>“Delegate”</Col>
                                        <Col xs={10}>Any person from a Client who has registered to attend an Event run or advertised by CSNS.</Col>
                                    </Row>
                                    <Row>
                                        <Col>“License”</Col>
                                        <Col xs={10}>At this point of purchase, you will select either a Single User or Multi-User license, which relates to your Subscription to a CSNS product, material and/or content and the use of the Products.</Col>
                                    </Row><Row>
                                        <Col>“Limited and insubstantial extracts”</Col>
                                        <Col xs={10}>A User may as part of a Subscription download limited and insubstantial portions of certain Products, CSNS material or Content (for example, part of a Data profile) for storage and internal distribution only to Personnel. This does not include articles or Data profiles in full.</Col>
                                    </Row><Row>
                                        <Col>“Online Services”</Col>
                                        <Col xs={10}>Online Products available on the Site.</Col>
                                    </Row><Row>
                                        <Col>“Order(s)”</Col>
                                        <Col xs={10}>An order or orders for a Product made by a Client through a Site or by email, telephone, or mail in accordance with these T&Cs.</Col>
                                    </Row><Row>
                                        <Col>“Order Confirmation”</Col>
                                        <Col xs={10}>The confirmation of your Order that CSNS sends to you (typically by email) that confirms the details of what you have ordered from CSNS.</Col>
                                    </Row><Row>
                                        <Col>“CSNS”</Col>
                                        <Col xs={10}>Refers to CSNSTM, including all wholly-owned and majority-owned affiliates, operating any brand names owned by CSNS. CSNS’s registered office is at 44 Gifford Avenue (temporary) Mt Roskill, Auckland 1041, New Zealand and the company is registered in New Zealand, nzbn: .</Col>
                                    </Row><Row>
                                        <Col>“Personnel”</Col>
                                        <Col xs={10}>Any employee of the Client, and/or (if applicable) any other person who is (i) nominated and identified by the Client and (ii) individually authorized by CSNS to have access to the Products purchased by the Client.</Col>
                                    </Row><Row>
                                        <Col>“Payment”</Col>
                                        <Col xs={10}>A payment required from a Client to CSNS in exchange for a Product.</Col>
                                    </Row><Row>
                                        <Col>“Product”</Col>
                                        <Col xs={10}>A product produced, distributed, supplied or made available or accessible by CSNS (including Books, Events and Subscriptions), including any combination of information produced or compiled by CSNS including but not limited to text-based news and feature articles, data, video and audio content in whatever delivery media format, including print and online. A full range of Products is available to review on the Site. “Product” includes any Online Service and Database Product.</Col>
                                    </Row><Row>
                                        <Col>“Registered User(s)”</Col>
                                        <Col xs={10}>Anyone who has registered his or her details on the Site and who has access to post an article for approval on behalf of his/her organisation, but who has not purchased a Subscription. A Registered User is subject to all applicable clauses in these T&Cs. CSNS reserves the right to change registrants’ access levels at any time.</Col>
                                    </Row><Row>
                                        <Col>“Services”</Col>
                                        <Col xs={10}>Any services as referenced in the Order Confirmation that are associated with and/or form part of the Product purchased by the Client.</Col>
                                    </Row><Row>
                                        <Col>“Site(s)”</Col>
                                        <Col xs={10}>www.civilsociety.nz</Col>
                                    </Row><Row>
                                        <Col>“Subscriptions(s)”</Col>
                                        <Col xs={10}>The term describing a registration or purchase made by a Client of a Product that provides a particular level of access to Products for a certain period for a certain number of Personnel (typically Single User subscriptions) who are then designated Users. The different access levels are categorized as Publication and User Subscriptions (free of charge), and Data Subscription (requiring payment). Details of the different Subscription levels are available on each of CSNS site. Subscriptions are provided by CSNS under those terms set out in Section (F) – Subscriptions.</Col>
                                    </Row><Row>
                                        <Col>“T&Cs”</Col>
                                        <Col xs={10}>These Terms and Conditions of business.</Col>
                                    </Row><Row>
                                        <Col>“Trial” and “Trialists”</Col>
                                        <Col xs={10}>CSNS may from time to time enable prospective Clients to have access to a Subscription Product free of charge and for a predetermined period. Some features of the Subscription may be disabled for a Trial and only those Personnel specified by the Client to us can be Trialists. All relevant parts of these T&Cs apply to Trialists.</Col>
                                    </Row><Row>
                                        <Col>“User(s)”</Col>
                                        <Col xs={10}>Any member of Personnel of a Client named in the Order Confirmation who has access rights to specified Products as part of a Subscription. This includes those individuals who have subscribed to a level of Subscription higher than Bronze (see definition of Registered User). Only these individuals have such rights of access to specified Products.</Col>
                                    </Row>
                                    <Row>
                                        <Col>“User Generated Content; “UGC”</Col>
                                        <Col xs={10}>Information posted by a Registered User or User on one of CSNS’s Sites that is visible to other Registered Users and Users.</Col>
                                    </Row>
                                </Container>
                                <br />
                                <p className="fw-bold">B. USER GENERATED CONTENT</p>
                                <br />
                                <p>Most of posts at CSNS Site are published by Registered Users with an approval of CSNS personnel. The Site also includes areas where comments and discussions can be posted publicly and which allow interaction among Users and between Users and CSNS personnel. Information posted to the Site by Users is called <span className="fw-bold">“User Generated Content” </span> or <span className="fw-bold">“UGC”</span>. CSNS encourages legitimate Users to participate in these ways on the understanding they adhere to any rules submitted or posted by them and approved by us on the Site. You will retain ownership of the copyright in any of your UGC that you or CSNS publish on the Site so you are free to re-use it as you wish. Subject to your personal data protection rights, you confirm that if you post UGC to the Site then you are granting CSNS a right (but not an obligation) unlimited in time to publish, re-use, archive, modify to match this T&Cs, delete or commercially exploit that UGC in whole or in part as CSNS sees fit, whether on the Site or otherwise, without any requirement to pay you for this and with or without attribution to you. You therefore grant CSNS a worldwide, non-exclusive, perpetual, irrevocable, royalty-free license to use any UGC you publish in whole or in part in any manner and for any purpose whatsoever and without further obligation to you. You also waive any moral rights that you may have in regard to the UGC. You are responsible for all the content of any of your UGC that you or CSNS publish. You are financially responsible to us for any claim against CSNS by any third party that your UGC is not in accordance with the rules given below or that otherwise relate to your UGC.</p>
                                <br />
                                <p>You undertake and agree that you will:</p>
                                <br />
                                <p>(a)    Only publish UGC that is your original content and which does not infringe the copyright or other rights of any third party when publishing such UGC.</p>
                                <br />
                                <p>(b)    Not post, link to or otherwise publish any UGC containing any form of advertising or promotion for goods and services or any spam or other form of unsolicited communication.</p>
                                <br />
                                <p>(c)    Not post, link to or otherwise publish any UGC with recommendations to buy or not buy particular share or other investment or which contain confidential information of another party or which otherwise have the purpose of affecting the price or value of any share or other investment.</p>
                                <br />
                                <p>(d)    Not post, link to or otherwise publish any UGC that is threatening, abusive (for any person, organisation, government, etc.), libelous, indecent or otherwise unlawful.</p>
                                <br />
                                <p>(e)    Not disguise the origin of any UGC and not impersonate any person (including CSNS employees), entity or government, or misrepresent any connection with any person or entity.</p>
                                <br />
                                <p>(f)     Not submit, post or otherwise publish any UGC that is unrelated to the Site.</p>
                                <br />
                                <p>(g)    Not submit, post or transmit any UGC that contains software viruses, files or code designed to interrupt, destroy or limit the functionality of the Site or any computer software or equipment. </p>
                                <br />
                                <p>(h)    Not collect or store other Users’ personal data.</p>
                                <br />
                                <p>(i)     Not restrict or prevent any other User from using the Site.</p>
                                <br />
                                <p>The Site will contain UGC submitted posts by Users over whom CSNS have no control beyond this T&Cs, so CSNS cannot guarantee the accuracy, integrity or quality of any UGC. You must be aware that other Users may not participate in posting UGC in a reasonable way and may post UGC that is misleading, untrue or offensive. You should understand that CSNS will fully monitor all UGC articles before releasing to public but cannot fully monitor all UGC on the Sites and where CSNS has actually noticed or received written notice of any UGC that is potentially misleading, untrue, offensive, unlawful, infringes third party rights or is potentially in breach of these T&Cs, then CSNS will review that UGC, decide whether to remove it from the Site and act accordingly. If you believe that any UGC published on the Site infringes any legal rights that you or others may have or is not allowed under these T&Cs, please notify us immediately with specific details by contacting us at info@csns.nz.</p>
                                <br />
                                <p className="fw-bold">C. BUSINESS TERMS AND CONDITIONS</p>
                                <br />
                                <p className="indentor fst-italic"> 1.	Placing an Order:</p>
                                <br />
                                <p>You can order any Product by email, telephone, mail or online. The relevant contact details for CSNS are available on CSNS’s Sites, in CSNS’s Products and on CSNS’s marketing material. CSNS’s general office details are also included above in these T&Cs. Orders placed by you for any of CSNS’s Products and/or Services shall only be binding when accepted by us as acknowledged by an Order Confirmation. Please note that any person who places an Order on your behalf that is accepted by CSNS can bind you legally. In the case of a Subscription Order, CSNS reserves the right to have your Subscription go live once CSNS has either received credit card authorization or Order confirmation email agreement from you.</p>
                                <br />
                                <p className="indentor fst-italic"> 2.	Online Access:</p>
                                <br />
                                <p>Where the Products are to be delivered through CSNS’s Sites, CSNS will, in the absence of any breach of the Agreement by you or any modification to the term agreed in advance by both parties, provide online access to the Products for a standard period of 12 months from the date the Order is accepted by us and you have User access to the relevant Site. All of CSNS’s Subscriptions have a standard minimum duration of 12 months. CSNS will use all reasonable efforts to make online access continuously available throughout that period but cannot guarantee that the online access will operate continuously or without interruptions or that it will be error free and CSNS do not accept any liability for its unavailability.</p>
                                <br />
                                <p className="indentor fst-italic"> 3.	Pricing of Products and Services:</p>
                                <p>CSNS will charge you in New Zealand Dollars. CSNS is obliged to use exchange rates that may not always correlate with the most current rates. CSNS will not provide refunds on perceived exchange rate differences nor accept payments made using exchange rates other than those used by CSNS.</p>
                                <br />
                                <p>While CSNS tries to ensure that all prices on the Sites and other marketing material are accurate and consistent, errors may occur. If CSNS discovers an error in the price of goods you have ordered, CSNS will inform you as soon as possible and give you the option of reconfirming your order at the correct price or cancelling it. If CSNS is unable to contact you CSNS will treat the order as cancelled. If, in that situation, you cancel and you have already paid for the Product, you will receive a full refund.</p>
                                <br />
                                <p className="indentor fst-italic"> 4.	Payment and Charges:</p>
                                <br />
                                <p>All payments made by you in relation to an Order must be made inclusive of GST or other relevant purchase tax, if any, or similar tax, and shall be made in full at one time. CSNS will try to process your Order promptly but does not guarantee that all the benefits of the Product ordered will be available to you by any specified time.  CSNS will notify you of any price increases prior to you renewing a Subscription. Eligibility for any discounts is ascertained at the time you order and cannot be applied retrospectively to an Order. In some cases, when you pay for an Order by credit card, your credit card company can apply extra charges to a transaction over and above the published fee. CSNS does not receive any portion of such extra charges and the credit card holder is responsible for such charges.</p>
                                <br />
                                <p className="indentor fst-italic"> 5.	Non-Payment of Fees to CSNS:</p>
                                <br />
                                <p>CSNS expects to be paid for any Product ordered at the point of sale and no later than 30 days from the date of ordering unless agreed in advance between you and CSNS. CSNS reserves the right to pursue all necessary means, including the imposition of late interest payment and issuance of legal proceedings, to recover payment from you for Products and Services you have ordered from us if full payment has not been received within the agreed period. CSNS also reserve the right to refuse subsequent orders for Products and Services from you or colleagues from the same institution as you until all outstanding payments owed to CSNS relating to orders made by you and/or others in the same institution have been cleared.</p>
                                <br />
                                <p className="fw-bold">D. BOOKS</p>
                                <br />
                                <p className="indentor fst-italic"> 1.	Delivery:</p>
                                <br />
                                <p>CSNS or the contracting company will send your Book to you by courier. However, if applies, CSNS is not responsible for local charges for customs clearance in your destination country. It is your responsibility to arrange for any payment relating to local customs charges and CSNS will not be responsible for any such charges or related delays in delivering the order to you.</p>
                                <br />
                                <p className="indentor fst-italic"> 2.	Cancellation:</p>
                                <br />
                                <p>CSNS provides detailed contents and sample extracts of CSNS’s Books on CSNS’s Site to ensure customers know what they are buying. CSNS recommends that you make full use of this information and if necessary, discuss your needs with CSNS’s (if applies) or the contracting company’s customer services team before ordering (a link will take you to their own website). CSNS does not provide a refund once a purchase of a Book has been made and an Order has been delivered while Book refund and any other requests should be addressed to the contracting company.</p>
                                <br />
                                <p className="fw-bold">E. SUBSCRIPTIONS (PUBLICATIONS, USERS, DATA)</p>
                                <br />
                                <p className="indentor fst-italic"> 1.	Types of Subscription and Subscription Options:</p>
                                <br />
                                <p>For now, CSNS offers three Subscription option to Clients, currently classified as Publication Subscription, User Subscription, and Data Subscription. These different levels of Subscriptions provide a range of benefits and access, are subject to change and the following terms apply to any Subscription you get. Publication and User Subscriptions are free of charge while Data Subscription offered to universities or government organisations for research purposes is subject to purchase.</p>
                                <br />
                                <p>Most commonly, you can get either a Publication Subscription, Data, or a User Subscription, which enables nominated Users (as set out in the relevant License), the benefits of a Subscription. You will not suffer or permit Personnel, other than the Users set out in the relevant License, to access or view anything more than limited and insubstantial extracts of CSNS’s Products or Content.  All Licenses are issued on a non-exclusive, non-transferable, limited and revocable basis for the duration of the relevant Subscription only.</p>
                                <br />
                                <p><span className="fw-bold">CSNS’s minimum Subscription period is 12 months, unless stipulated on the purchase page on the Site.</span> You may be able to arrange for a Trial of Publication Subscription that interest your civil society organisation by speaking to your CSNS contact or by emailing CSNS at: subscriptions@csns.nz. If you or other Personnel take a Trial then you are subject to these terms wherever applicable during the entire term of the Trial period.</p>
                                <br />
                                <p>These terms apply to both types of Subscription. To arrange for such a Subscription you should speak to your CSNS contact or email us at subscriptions@csns.nz.</p>
                                <br />
                                <p className="indentor fst-italic"> 2.	Subscription Termination:</p>
                                <br />
                                <p>CSNS reserves the right to terminate a Subscription without notice if in CSNS’s reasonable opinion CSNS considers that the Subscription is being used in ways that contravene the terms described herein. This can include evidence of multiple and/or simultaneous access to Products either directly (by sharing username) or indirectly, including without limitation via dissemination of CSNS Products, material and/or content (in whole or in part and in any medium) to Personnel or third parties other than the licensed User(s).</p>
                                <br />
                                <p>CSNS reserves the right to temporarily restrict Subscription access to the service where payment is not timely made. This action will only be taken once attempts to secure payment have been exhausted in CSNS’s view and after informing the Subscriber of CSNS’s intention. Once payment is received access will be promptly restored.</p>
                                <br />
                                <p>In these cases, no refund will be given. See also Section 5 below.</p>
                                <br />
                                <p className="indentor fst-italic"> 3.	Terms Applying to all Subscriptions:</p>
                                <br />
                                <p>When you get a Subscription, you have access to the features of that Subscription as detailed on the relevant Site. You will receive a username for each nominated User licensed on the Subscription. Nominated Users may not share their username with anyone else.</p>
                                <br />
                                <p>(a)  When a Subscription is made (or a Trial is taken) by you in relation to both electronic forms and/or hard copy of the Products and regardless of the means of access or delivery you may: </p>
                                <br />
                                <p>(i)  Display such Products to nominated Users (for the avoidance of doubt these are the Users licensed on the Subscription that you took out with us).</p>
                                <br />
                                <p>(ii)  Download and store limited and insubstantial extracts of Products in machine readable form for use by nominated Users.</p>
                                <br />
                                <p>(iii) Use limited and insubstantial extracts from the Products for external purposes provided that such use is in the ordinary course of your business and does not form part of a regular or routine pattern of use and provided further that where extracts of the Products are contained in documents which are to be included in press releases and/or otherwise made publicly available, such extracts of the Products shall (unless otherwise agreed in writing with CSNS) not be released unless the following conditions are complied with: (A) either a proof, copy or relevant section of the document are supplied to CSNS for release authorization prior to external distribution (CSNS will not unreasonably withhold or delay such authorization); (B) CSNS’s copyright notice is included in the document; and (C) expressly and with reasonable prominence, CSNS is acknowledged as the source of the Products so used. You should contact us by email at info@csns.nz to receive such authorization.</p>
                                <br />
                                <p>(iv) Allow nominated Users to use the Data for internal analysis in the ordinary (internal) course of your business with referencing source of the Data – the CSNS.</p>
                                <br />
                                <p>(v)  Use limited and insubstantial extracts from the Products in internal business reports circulated to Personnel, if such use is in the ordinary (internal) course of your business and does not form part of a regular or routine pattern of use.</p>
                                <br />
                                <p>(vi)  Save only as expressly permitted in accordance with clause (c) above, allow nominated Users to use such Products solely for their internal business purposes.</p>
                                <br />
                                <p>(b)  When a Subscription is purchased (or a Trial is taken) by you (relating to both electronic forms and/or hard copy of the Products and regardless of the means of access or delivery) you may not:</p>
                                <br />
                                <p>(i)  Download, store, reproduce, transmit, display, copy, distribute, commercially exploit or use the Products and/or Data other than as expressly permitted in Section 3(a) above.</p>
                                <br />
                                <p> (ii)  Resell, sub-license, rent, lease, transfer or attempt to assign the rights in the Products and/or Data (in whole or in part) to any other person.</p>
                                <br />
                                <p>(iii)  Use the Products and/or Data in any manner, (or transfer or export the Products and/or Data or any copies thereof into any country), other than in compliance with applicable laws.</p>
                                <br />
                                <p>(iv)  Allow the nominated Users to use the Products and/or Data other than in accordance with these terms nor may you allow any person other than the nominated Users to use and/or gain access to the Products and/or Data.</p>
                                <br />
                                <p>(v)   Modify or alter such Products and/or Data.</p>
                                <br />
                                <p>(vi)  Create a database in electronic or structured manual form by systematically downloading and storing any of the content from such Products and/or Data.</p>
                                <br />
                                <p>(vii)  Exceed the number of Users agreed with CSNS in the relevant License or otherwise agreed with CSNS in writing.</p>
                                <br />
                                <p>(viii)  Allow Personnel other than the Users to access or view anything more than limited and insubstantial extracts of the Products, CSNS material or content. If CSNS reasonably suspects that you are in breach of this provision, without prejudice to its other rights or remedies, CSNS may: (A) charge you for such Personnel as may have accessed or viewed such extracts; (B) suspend your Subscription; and/or (C) terminate your Subscription.</p>
                                <br />
                                <p className="indentor fst-italic"> 4.	Short-Term Data Subscriptions:</p>
                                <br />
                                <p>From time to time CSNS may offer shorter term Data Subscriptions, which are typically for three months and available to research based Data Users only (universities, government organisations), to be purchased online. Any shorter-term Data Subscriptions that CSNS may offer are for the minimum term set out in the relevant License and the cancellation rights set out in Section 5 below shall not apply to such Data Subscriptions. All Subscriptions are renewable at the standard 12-month term.</p>
                                <br />
                                <p className="indentor fst-italic"> 5.	Subscription Cancellation:</p>
                                <br />
                                <p>(a)    Free Subscriptions can be cancelled any time. If you have purchased a Data Subscription you may cancel the Subscription within 30 days from the date of the Subscription going live (that is, the date when you have access to all the benefits of the Subscription you have ordered). In this case, you will receive a refund representing 60% of your Subscription payment. Refunds will be made within 30 days of CSNS confirming the cancellation. No refund will be given if a Data Subscription is cancelled once 30 days have passed from the Subscription going live.</p>
                                <br />
                                <p>All cancellations must be made in writing (by letter or email – proof of delivery will be required) and sent to the Head of Customer Services at CSNS’s by email: subscriptions@csns.nz.</p>
                                <br />
                                <p>(b)    Subsequent Term.  Confirmation of your Subscription renewal is taken in writing (by email or letter) and thereafter you are liable for the stipulated cost of the renewal term. For clarity, payment is required where outstanding and no refund is available at either. Please note, confirmation is required in the calendar month preceding your current Subscription term expire. After this, prevailing published rates per User apply as standard.</p>
                                <br />
                                <p>CSNS reserves the right to terminate a Subscription at any time and without any refund if in CSNS’s reasonable opinion that Subscription has been subject to activity outside the terms of use stipulated in these T&Cs. Please note you can substitute a User rather than cancelling a Subscription (see below).</p>
                                <br />
                                <p className="indentor fst-italic"> 6.	User Substitution:</p>
                                <br />
                                <p>A Client is free to change a nominated User during any Subscription term by emailing CSNS’s customer services team at subscriptions@csns.nz. CSNS reserves the right to refuse a substitution request if, for example, it requires a Subscription to be transferred to a different institution or Client. If a substitution is refused CSNS’s standard cancellation policy applies.</p>
                                <br />
                                <p className="indentor fst-italic"> 7.	Subscription Queries:</p>
                                <br />
                                <p>If you have any questions about which Subscription to register or about a Subscription you have purchased, please speak to your CSNS contact or email us at: subscriptions@csns.nz.</p>
                                <br />
                                <p className="fw-bold">F. EVENTS</p>
                                <br />
                                <p className="indentor fst-italic"> 1. Event Fee:</p>
                                <br />
                                <p>CSNS can organise some Events but also will host multiple Event announcements & advertisements of a third party – the advertising agency (“AA”). CSNS does not hold any responsibility for Event organization and its payment actions announced or advertised by AA. </p>
                                <br />
                                <p>The CSNS Event fee entitles the Delegate to attend all non-restricted Event sessions and refreshment breaks, including coffee breaks, lunch and the event cocktail reception. Note that some certain sessions and receptions may be invite-only or restricted to a particular type of Delegate.  The fee also includes event materials, which will be presented to the Delegate at the Event and any subsequent materials made available to Delegates online after the Event. Where applicable all Event Delegate fees will be subject to local purchase tax rates.</p>
                                <br />
                                <p className="indentor fst-italic"> 2. Payment:</p>
                                <br />
                                <p>Payment in full must be made to CSNS prior to the Event taking place. Payments can be made by American Express, Visa, MasterCard, company cheque or by wire transfer. If registering four weeks or closer to the Event date, Delegates MUST pay by credit card.</p>
                                <br />
                                <p className="indentor fst-italic"> 3. Delegate Cancellation and Non-Attendance:</p>
                                <br />
                                <p>All cancellations must be made in writing (by letter or email – proof of receipt will be required) to the relevant contact at CSNS Ltd for that Event.</p>
                                <br />
                                <p>Any cancellation received more than 14 days prior to the Event date will be subject to a cancellation fee equal to 50% of the Event fee; however, you may elect to receive a credit in the amount of the cancellation fee to be applied to future CSNS event occurring within 12 months from the date of issuance of the credit.</p>
                                <br />
                                <p>No refunds will be made for cancellations made within 14 days of Event, however, you will receive a credit in the amount of 100% of the Event fee paid to be used at another CSNS event occurring within 12 months from the date of issuance of the credit.</p>
                                <br />
                                <p>Please also note that you can substitute another person instead of cancelling (see below); provided, if you do not advise CSNS that you are cancelling your Delegate place(s) or that you are substituting another person or people in your place(s) then you remain liable for the full Delegate fee(s). Non-attendance at the Event does not waive your liability to pay for the Delegate place(s) booked by you or on your behalf and CSNS reserves the right to use all necessary means to receive payment from you, including the issuance of legal proceedings. For more information regarding refund, complaint and/or program cancellation policies, please contact services@csns.nz.</p>
                                <br />
                                <p>For virtual events, due to the nature of virtual events and the immediate value delivery that they entail: All orders (sponsorship and delegate) are non-refundable, non-creditable and non-transferable from the day of commitment.</p>
                                <br />
                                <p className="indentor fst-italic"> 4. Substitution Policy:</p>
                                <br />
                                <p>Substitutions within organizations may be arranged as long as CSNS is notified in writing in advance of the Event. No substitutions may be made with a Delegate from another company and under no circumstances can a delegate registration be shared.</p>
                                <br />
                                <p className="indentor fst-italic"> 5. Postponement:</p>
                                <br />
                                <p>In the event that CSNS postpones an Event, delegates registered for the Event have the option to either wait to attend the Event at the rescheduled time (CSNS to retain the Delegate payment if already made) or to have the payment credited to a future CSNS event. This credit will be available for use for the next 12 months from the date that the credit is issued by CSNS.</p>
                                <br />
                                <p className="indentor fst-italic"> 6. Event Cancellation:</p>
                                <br />
                                <p>If CSNS is obliged to cancel any Event then any Client will be offered the choice of either transferring their registration to another CSNS Event of their choice that is scheduled in the next 12 months or to receive a full refund of any payment received by CSNS for that particular Event from that Client. Any refunds will be made within 30 clays of written notice having been issued by CSNS that the Event has been cancelled.</p>
                                <br />
                                <p className="indentor fst-italic"> 7. Force Majeure:</p>
                                <br />
                                <p>If for any reason beyond CSNS’s reasonable control (including but not limited to: strikes; acts, regulations or orders of governmental authorities; civil disorder; disasters; acts of terrorism; acts of war; acts of God) it is impossible to run an Event, CSNS will as a first choice look to postpone the Event (see Postponement above) but reserves the right to retain some or all delegate fees as a contribution to all venue and administrative costs incurred.</p>
                                <br />
                                <p className="indentor fst-italic"> 8. Travel and Accommodation:</p>
                                <br />
                                <p>CSNS is not accountable for booking any transportation or accommodation required by Delegates nor for any transfers to and from the event venue. Although CSNS or the contracting agencies can work with the hotel hosting an Event to secure a preferential delegate rate for those attending the Event there, CSNS makes no assurances as to the availability of this rate nor of the hotel services offered. CSNS will not accept liability for any public or individual transport disruption or deferral. In such circumstances, CSNS’s standard cancellation policy applies.</p>
                                <br />
                                <p>We advise that you do not engage with any third-party travel company. If directly contacted by a third party, we advise that you are vigilant and do not hand over any personal details. If in doubt, please contact services@csns.nz.</p>
                                <br />
                                <p className="indentor fst-italic"> 9. Speakers and Agendas:</p>
                                <br />
                                <p>Please be aware that all speakers at the advertised Events are confirmed at the time of the published agenda, however, circumstances beyond the control of CSNS or the contracting agency may necessitate substitutions, alterations or cancellations of the speakers and/or topics. CSNS reserves the right to alter or modify the advertised speakers and/or agenda topics if necessary it the Event was organised by CSNS itself. Please visit the relevant CSNS pages or the website of contracting agency (through the link provided in event details), which are updated regularly.</p>
                                <br />
                                <p className="indentor fst-italic"> 10. Acceptance of Registration:</p>
                                <br />
                                <p>CSNS reserves the right to decline a registration for an Event for any reason at any time up to and including the first day of the Event. CSNS will refund any payment in full relating to any such registration that CSNS chooses to decline within 30 days of notifying the Delegate of this decision.</p>
                                <br />
                                <p className="fw-bold"> G. SERVICES</p>
                                <br />
                                <p className="indentor fst-italic"> 1.	Force Majeure and Service Cancellation:</p>
                                <br />
                                <p>If for any reason beyond CSNS’s reasonable control (including but not limited to: strikes; acts, regulations or orders of governmental authorizes; civil disorder; disasters; acts of terrorism; acts of war; acts of God (such as hurricane, flooding, earthquake, volcanic eruption, communicable disease (such as covid19), etc.) it is impossible to run the above-mentioned event, then in those circumstances, CSNS will seek to reschedule the event. Upon rescheduling, your sponsorship package and benefits will be transferred automatically to the newly scheduled event. Should CSNS be unable to reschedule the event the sponsor will be credited the fee to use across any other CSNS event in the following 51 weeks (up to).</p>
                                <br />
                                <p>For virtual events, due to the nature of virtual events and the immediate value delivery that they entail: All orders (sponsorship and delegate) are non-refundable, non-creditable and non-transferable from the day of commitment.</p>
                                <br />
                                <p>The Sponsor shall have the right to cancel its sponsorship of this event up to 90 business days prior to the start of the event and will be allowed to apply sponsorship fees toward another CSNS event in the following 51 weeks (up to), however, 100% of sponsorship fee must be paid prior to the start of the originally contracted event and prior to any credit being issued.</p>
                                <br />
                                <p>Should this other event take place prior to the originally contracted event, said monies will be due no later than 30 business days prior to the start of the new event.</p>
                                <br />
                                <p>Should the Sponsor cancel its sponsorship within 90 business days of the commencement of the event the fees due to CSNS will equal 100% of the sponsorship fee, without the right to transfer credit to another event, and must be paid in full no later than 30 business days prior to the start of the event. Should sponsor fail to meet agreed upon payment terms, CSNS reserves the right to reassign or cancel sponsorship benefits.</p>
                                <br />
                                <p>The Sponsor shall observe all union contracts in effect between CSNS, the event contractor, the Facility, and various labor organizations. The Sponsor shall obtain, at its own expense, adequate insurance covering sponsor’s participation in the event. The Sponsor may not assign this contract without the prior written consent of CSNS.</p>
                                <br />
                                <p>Any and all matters not specifically covered herein are subject to the sole discretion of CSNS. Your signature on the Agreement indicates that you have reviewed this Agreement and agree to the terms set forth herein.  You also certify that you are authorized on behalf of sponsor organization to bind sponsor organization to the terms of this Agreement.  This document, when signed by sponsor organization constitutes a binding legal agreement. Sponsor organization is bound by all the terms and conditions of this Contract.  Furthermore, the parties agree that the terms of this Agreement are confidential and shall not be disclosed to a third party without the written consent of CSNS.</p>
                                <br />
                                <p className="indentor fst-italic"> 2.	Compliance with applicable laws including those relating to data privacy:</p>
                                <br />
                                <p>Both parties must comply with all applicable laws in connection with the provision and the use of the Services and Deliverables, including but not limited to those related to data privacy and protection of personal data.</p>
                                <br />
                                <p className="indentor fst-italic"> 3.	Sponsor Wish lists</p>
                                <br />
                                <p>All sponsor wish lists must be received no later than 8 weeks out from the event.</p>
                                <br />
                                <p className="fw-bold"> H. MARKETING SOLUTIONS / ADVERTISING</p>
                                <br />
                                <p className="indentor fst-italic"> 1.	General Obligations:</p>
                                <br />
                                <p> You will comply with any requirements which we may reasonably specify in connection with the proposed publication of the Advertisement including (without limitation) technical specifications relating to standard and quality and any requirements as to setting, style, delivery format, file configuration, size and wording (‘Advertiser Dependencies’).</p>
                                <br />
                                <p> If you change your name, trading style, identity or address or any other of the details disclosed by you to CSNS, you will give CSNS written notification of the change in question as soon as reasonably possible after becoming aware that the change will take place and in any event within five (5) working days of the change taking effect.</p>
                                <br />
                                <p className="indentor fst-italic"> 2.	Your Representations, Warranties and Undertakings</p>
                                <br />
                                <p>Where you are the Advertiser’s agent, you further represent, warrant and undertake that you are authorised by the Advertiser to place the Advertisement(s) with CSNS.</p>
                                <br />
                                <p>You hereby further represent, warrant and undertake as follows:</p>
                                <br />
                                <p>The publication of the Advertisement by CSNS in whatever medium in the form originally submitted by you (or as amended in circumstances permitted by these terms) will not breach any contract with a third party or infringe the intellectual property or other proprietary rights of any third party or otherwise be unlawful or render CSNS liable to any proceedings, claims, demands, costs or expenses or any other loss or damage;</p>
                                <br />
                                <p>You have obtained all necessary licences, permits, consents, approvals, authorisations and permissions required to publish each Advertisement submitted for publication by you;</p>
                                <br />
                                <p>Without prejudice to the generality of above, in the case of any Advertisement submitted for publication by you which contains the name or pictorial representation (whether photographic or otherwise) of any living person or any part of the anatomy of any living person or any material by which any living person may be identified (whether by that material alone or in combination with other material), you have obtained the authority of the relevant person to make use of the same;</p>
                                <br />
                                <p>The Advertisement complies with the requirements of all applicable laws and regulations (including subordinate and supervening legislation and the rules of any relevant statutorily recognised regulatory authority);</p>
                                <br />
                                <p>The advertising copy submitted by you to CSNS is legal, decent, honest, truthful, complete and accurate and complies with the New Zealand Advertising Standards Code (Code) and all other relevant codes under the general supervision of the Advertising Standards Authority (ASA) or any successor body; </p>
                                <br />
                                <p>Instructions, artwork and material submitted by you to CSNS via electronic means will not introduce into our information and communications SOCIETIES systems or infrastructure any software, program or code designed or intended to infiltrate or damage a computer system without the owner’s informed consent and will not in any other way destroy, damage or corrupt any software or data on those systems and infrastructure;</p>
                                <br />
                                <p>You have retained sufficient quality and quantity of all artwork, film, copy and other material relating to the Advertisement and that CSNS will not be liable for loss of or damage to any of the foregoing following submission of the same by you to CSNS;</p>
                                <br />
                                <p>Where you supply creative content for an Advertisement to be published in an Online Publication and such content contains a link to a website, nothing in that Advertisement or the content of the linked-to website is or will be defamatory or objectionable or otherwise likely to bring CSNS into disrepute.</p>
                                <br />
                                <p className="indentor fst-italic"> 3.	Marketing Solutions & Advertisements: Responsibility and Liability</p>
                                <br />
                                <p>You will be required to, at any time amend any artwork, materials and copy relating to any Advertisement for the purposes of:</p>
                                <br />
                                <p>
                                    <li>Complying with any legal or moral obligations placed on CSNS or you or the Advertiser;</li>
                                    <li>Avoiding the infringement of the rights of any third party, or the New Zealand Advertising Standards Code and all other relevant codes under the general supervision of the Advertising Standards Authority or any other applicable laws or regulations; or </li>
                                    <li>Meeting the production and quality specifications stipulated on the specification sheet.</li>
                                </p>
                                <br />
                                <p> You will deliver to CSNS in a digital format or file configuration approved by CSNS all copy(ies) for the relevant Advertisement by the advertising artwork delivery deadline specified on your contract.</p>
                                <br />
                                <p>If you fail to submit the relevant copy in compliance with the above requirements, CSNS may publish the Advertisement at an alternative time of our choosing.</p>
                                <br />
                                <p>CSNS may remove any Advertisement which contains content or links to a website which, at our absolute discretion, is (or is likely to be) defamatory or objectionable or otherwise likely to bring CSNS or any member of CSNS into disrepute.</p>
                                <br />
                                <p>If CSNS receive complaints about the content of an Advertisement, CSNS may, at our discretion and without notice to you or to the Advertiser, remove the Advertisement from display. </p>
                                <br />
                                <p className="indentor fst-italic"> 4.	Prices and Payment</p>
                                <br />
                                <p>Total costs are payable strictly within 30 days of the invoice payment due date.</p>
                                <br />
                                <p className="indentor fst-italic"> 5.	Order Cancellation</p>
                                <br />
                                <p> Please refer to your Contract for cancellation timelines.</p>

                            </div>
                        </div>
                    </div>
                    <div className="space-50" />
                </Fragment>
            </BreadCrumb>
            <div className="space-70" />
            <BannerSection />
        </Fragment>
    )
};

export default TermsConditionsPage;