import React, {Fragment} from 'react';
import {Link} from "react-router-dom";

const FooterNewsCategories = () => {
    return (
        <Fragment>
            <h3 className="widget-title">News categories</h3>
            <div className="row">
                <div className="col-lg-6">
                    <ul>
                        <li><Link to="/">Social Cohesion</Link></li>
                        <li><Link to="/">Community Development</Link></li>
                        <li><Link to="/">Education / Traning / Research</Link></li>
                        <li><Link to="/">Sports / Recreation</Link></li>
                        <li><Link to="/">Arts / Culture / Heritage</Link></li>
                        <li><Link to="/">Accomodation / Housing</Link></li>
                        <li><Link to="/">Employment</Link></li>
                    </ul>
                </div>
                <div className="col-lg-6">
                    <ul>
                        <li><Link to="/">People with Disablities</Link></li>
                        <li><Link to="/">Religious Activities</Link></li>
                        <li><Link to="/">Promotion of Volunteering</Link></li>
                        <li><Link to="/">Fund-Raising</Link></li>
                        <li><Link to="/">Education</Link></li>
                        <li><Link to="/">Corrections</Link></li>
                        <li><Link to="/">Other</Link></li>                                             
                    </ul>
                </div>
            </div>
        </Fragment>
    );
};

export default FooterNewsCategories;