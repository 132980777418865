import React from 'react';
import { Link } from "react-router-dom";

const quick_links = [
    {
        name: 'About',
        link: '/about'
    },
    {
        name: 'Archive',
        link: '/archive'
    },
    {
        name: 'Advertise',
        link: '/advertise'
    },
    {
        name: 'Terms & Conditions',
        link: '/termsandconditions'
    }

];

const FooterCopyright = () => {

    return (
        <div className="copyright">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 align-self-center inline">
                        <p>&copy; Copyright {new Date().getFullYear()}, All Rights Reserved</p>
                    </div>
                    <div className="col-lg-7 align-self-center">
                        <div className="copyright_menus text-end">
                            <div className="language" />
                            <div className="copyright_menu inline">
                                <ul>
                                    {quick_links.map((item, i) => (
                                        <li key={i}><Link to={item.link}>{item.name}</Link></li>

                                    ))}
                                    <li className="link ms-lg-5">
                                        <p>Crafted by<span className='text-blue'>
                                            <a href="https://www.intellapps.io/" target="_blank" rel="noreferrer">
                                                IntellApps™
                                            </a></span>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterCopyright;