import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'
import App from './containers/App/App';
import { BrowserRouter } from 'react-router-dom';
import store from '../src/store/store';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './assets/scss/master.scss';
import 'font-awesome/css/font-awesome.min.css';
import 'react-modal-video/scss/modal-video.scss';

import 'swiper/scss';
import 'swiper/scss/grid';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <BrowserRouter forceRefresh={true}>
            <App />
        </BrowserRouter>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
