import React, { useState } from 'react';
import FontAwesome from "../uiStyle/FontAwesome";
import { Link, NavLink } from "react-router-dom";
import SidebarMenu from "../SidebarMenu";
import SearchModal from "../SearchModal";
import tempIcon from '../../assets/img/icon/temp.png';

import { connect, Provider } from 'react-redux'
// added redux-persist because my store was being erased after the api call with city+country
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from '../../store/redux/store'

//import CityCountryForm from './components/CityCountryForm'
import CurrentLocation from './components/CurrentLocation'
import WeatherContainer from './components/WeatherContainer'

const menus = [
    {
        id: 1,
        link: '/',
        linkText: 'Home',
    },
    {
        id: 2,
        linkText: 'Societies',
        child: true,
        icon: 'angle-down',
        submenu: [
            {
                id: 21,
                link: '/about',
                linkText: 'Social Cohesion'
            },
            {
                id: 22,
                link: '/archive',
                linkText: 'Community Development'
            },
            {
                id: 23,
                link: '/contact',
                linkText: 'Education / Traning / Research'
            },
            {
                id: 24,
                link: '/404',
                linkText: 'Sports / Recreation'
            },
            {
                id: 24,
                link: '/404',
                linkText: 'Arts / Culture / Heritage'
            },
            {
                id: 24,
                link: '/404',
                linkText: 'Accomodation / Housing'
            },
            {
                id: 24,
                link: '/404',
                linkText: 'Employment'
            },
            {
                id: 24,
                link: '/404',
                linkText: 'Fund-Raising'
            },
            {
                id: 24,
                link: '/404',
                linkText: 'People with Disablities'
            },
            {
                id: 24,
                link: '/404',
                linkText: 'Religious Societies'
            },
            {
                id: 24,
                link: '/404',
                linkText: 'Promotion of Volunteering'
            },
            {
                id: 24,
                link: '/404',
                linkText: 'Other'
            },
        ]
    },
    /*{
        id: 3,
        linkText: 'Posts',
        child: true,
        icon: 'angle-down',
        submenu: [
            {
                id: 31,
                child: true,
                linkText: 'General Posts',
                third_menu: [
                    {
                        id: 311,
                        link: '/home-two/post1',
                        linkText: 'Post 1',
                    },
                    {
                        id: 312,
                        link: '/home-two/post2',
                        linkText: 'Post 2',
                    },
                    {
                        id: 313,
                        link: '/home-two/post3',
                        linkText: 'Post 3',
                    },
                ],
            },
            {
                id: 32,
                child: true,
                linkText: 'Video Posts',
                third_menu: [
                    {
                        id: 321,
                        link: '/home-two/video_post1',
                        linkText: 'Video Style 1',
                    },
                    {
                        id: 322,
                        link: '/home-two/video_post2',
                        linkText: 'Video Style 2',
                    },
                    {
                        id: 323,
                        link: '/home-two/video_post3',
                        linkText: 'Video Style 3',
                    },
                ],
            },
            {
                id: 33,
                child: true,
                linkText: 'Audio Posts',
                third_menu: [
                    {
                        id: 331,
                        link: '/home-two/audio_post1',
                        linkText: 'Audio Style 1',
                    },
                    {
                        id: 332,
                        link: '/home-two/audio_post2',
                        linkText: 'Audio Style 2',
                    },
                    {
                        id: 333,
                        link: '/home-two/audio_post3',
                        linkText: 'Audio Style 3',
                    },
                ],
            },
            {
                id: 34,
                child: true,
                linkText: 'Sidebars',
                third_menu: [
                    {
                        id: 341,
                        link: '/home-two/post1',
                        linkText: 'Right Sidebar',
                    },
                    {
                        id: 342,
                        link: '/home-two/left_post2',
                        linkText: 'Left Sidebar',
                    },
                    {
                        id: 343,
                        link: '/home-two/post2',
                        linkText: 'No Sidebar',
                    },
                ],
            },
        ]
    },*/
    {
        id: 4,
        linkText: 'Governance',
        child: true,
        icon: 'angle-down',
        submenu: [
            {
                id: 41,
                link: '/business',
                linkText: 'Ministry of Health'
            },
            {
                id: 42,
                link: '/entertainment',
                linkText: 'Ministry for Ethnic Communities'
            },
            {
                id: 43,
                link: '/features',
                linkText: 'Ministry of Buisness, Innovation & Employment'
            },
            {
                id: 44,
                link: '/sports',
                linkText: 'Immigration New Zealand'
            },
        ]
    },
    {
        id: 5,
        linkText: 'Events',
        child: true,
        icon: 'angle-down',
        submenu: [
            {
                id: 41,
                link: '/calendar',
                linkText: 'Cultural Celebration'
            },
            {
                id: 42,
                link: '/calendar',
                linkText: 'Conference / Discussion Panel'
            },
            {
                id: 43,
                link: '/calendar',
                linkText: 'Trainings / Workshops'
            },
            {
                id: 44,
                link: '/calendar',
                linkText: 'Other Services'
            },
        ]
    },
    {
        id: 5,
        linkText: 'Training',
        child: true,
        icon: 'angle-down',
        submenu: [
            {
                id: 41,
                link: '/404',
                linkText: 'Language'
            },
            {
                id: 42,
                link: '/404',
                linkText: 'Workshops'
            },
            {
                id: 43,
                link: '/404',
                linkText: 'Sport Activities'
            },
            {
                id: 44,
                link: '/404',
                linkText: 'Buisness'
            },
        ]
    },
    {
        id: 6,
        linkText: 'Resources',
        child: true,
        icon: 'angle-down',
        submenu: [
            {
                id: 60,
                link: '/404',
                linkText: 'Research'
            },
            {
                id: 61,
                link: '/404',
                linkText: 'Reports'
            },
            {
                id: 62,
                link: '/404',
                linkText: 'Books'
            },
        ]
    },
    /*{
        id: 5,
        linkText: 'World',
        link: '/home-two/world'
    },*/

    {
        id: 7,
        linkText: 'Contact',
        link: '/contact'
    },
];


const MainMenuTwo = ({ currentLocation, weatherData }) => {
    const month = ["Jan","Feb","Mar","Apr","May","June","July","Aug","Sep","Oct","Nov","Dec"];
    const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
    const current = new Date();
    let wday = weekday[current.getUTCDay()];
    let mon = month[current.getUTCMonth()];
    let mday = current.getUTCDate();
    let year = current.getUTCFullYear();

    const [searchShow, setSearchShow] = useState(false);
    const [sideShow, setSideShow] = useState(false);
        return (
            <div className="container-1">
                <div className="main-menu">
                    <div className="main-nav is-ts-sticky">
                        <div className="row justify-content-between"> 
                            <nav className="navbar justify-content-center navbar-expand-lg col-lg-8 align-self-center">
                                <div className="site-nav-inner">
                                    <button className="navbar-toggler" onClick={() => setSideShow(true)}>
                                        <FontAwesome name="bars"/>
                                    </button>
                                    <div id="navbarSupportedContent"
                                        className="collapse navbar-collapse navbar-responsive-collapse">
                                        <ul className="nav navbar-nav" id="scroll">
                                            {menus.length > 0 ? menus.map((item, i) => (
                                                <li key={i}
                                                    className={`
                                                    ${item.child ? 'dropdown' : ''}
                                                    nav-item`}>
                                                    {item.child ? <NavLink onClick={e => e.preventDefault()} to="/"
                                                                className="menu-dropdown"
                                                                data-bs-toggle="dropdown">{item.linkText}
                                                            <FontAwesome
                                                                name={item.icon}/></NavLink>
                                                        : <NavLink to={item.link} className="menu-dropdown"
                                                                data-bs-toggle="dropdown">{item.linkText} <FontAwesome
                                                            name={item.icon}/></NavLink>}
    
                                                    {item.child ?
                                                        <ul className="dropdown-menu" role="menu">
                                                            {item.submenu.map((sub_item, i) => (
                                                                <li key={i}
                                                                    className={`${sub_item.child ? 'dropdown-submenu' : null}
                                                            `}>
                                                                    {sub_item.child ?
                                                                        <NavLink onClick={e => e.preventDefault()}
                                                                                to="/">{sub_item.linkText}</NavLink>
                                                                        : <NavLink
                                                                            to={sub_item.link}>{sub_item.linkText}</NavLink>}
                                                                    {sub_item.third_menu ?
                                                                        <ul className="dropdown-menu">
                                                                            {sub_item.third_menu.map((third_item, i) => (
                                                                                <li key={i}><NavLink
                                                                                    to={third_item.link}>{third_item.linkText}</NavLink>
                                                                                </li>
                                                                            ))}
                                                                        </ul> : null}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                        : null
                                                    }
                                                </li>
                                            )) : null}
                                        </ul>
                                    </div>
                                    <SidebarMenu className="themeBlue" sideShow={sideShow} setSideShow={setSideShow} menus={menus}/>
                                </div>
                                <div className="col-lg-3 d-none d-sm-none d-lg-flex justify-content-end align-self-start">
                                    <div className="date3">
                                        <p>{wday}, {mon} {mday}, {year}</p>
                                    </div>
                                    <div className="search4" onClick={() => setSearchShow(!searchShow)}><FontAwesome name="search"/></div>
                                </div>

                                <Provider store={ store }>
                                    <PersistGate loading={null} persistor={persistor}>
                                        <div className="align-self-center">
                                            <div className="temp d-none d-lg-inline">
                                                <div className="temp_wap">                                                  
                                                    <div>
                                                        <CurrentLocation />
                                                        <div id="weather" className="button">
                                                            {currentLocation !== null && weatherData !== null && <WeatherContainer />}
                                                        </div>
                                                    </div>  
                                                </div>     
                                            </div>
                                        </div>                                  
                                    </PersistGate>
                                </Provider>
                            
                            </nav>
                            <div className="col justify-content-end d-flex d-lg-none align-self-start">
                                <div className="date3 ">
                                    <p>{wday}, {mon} {mday}, {year}</p>
                                </div>
                                <div className="search4" onClick={() => setSearchShow(!searchShow)}><FontAwesome name="search"/></div>
                            </div>

                            

                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const mapStateToProps = state => {
        return {
            currentLocation: state.location,
            weatherData: state.weather
        }
    }
    
    //export default LogoAreaTwo;
    export default connect(mapStateToProps)(MainMenuTwo)