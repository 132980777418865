import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import logo from '../../assets/img/logo/logo.png';


const LogoAreaTwo = ({ currentLocation, weatherData }) => {

    const [search, setSearch] = useState('');
    const submitHandler = e => {
        e.preventDefault();
        setSearch('')
    };

    return (
        <div className="logo_area white_bg">
            <div className="container-1">
                <div className="row">
                    <div className="col-lg-3 align-self-center">
                        <div className="logo">
                            <Link to="/">
                                <img src={logo} alt="logo" />
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-5 align-self-center">
                        <div className="signup_form header_search3">
                            <form onSubmit={submitHandler}>
                                <input className="signup" type="email" placeholder="Your email address" value={search}
                                    onChange={e => setSearch(e.target.value)} />
                                <button type="submit" className="cbtn">sign up</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LogoAreaTwo;
